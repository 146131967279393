<template>
 <div v-if="navigationDialog" class="filterDrawer bg-white sm:w-96 top-0 right-0 shadow-xl"
    style="position: fixed;height: 100%; z-index: 100;top: 0;overflow-x: hidden;transition: 0.5s;border-radius: 0px !important">
  <div>
    <div class="bottom_space p-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
      <div>
        <p class="">Filter</p>
      </div>
      <div class="flex gap-2">
        <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="cancelFilterOptions"/>
        <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Apply'"  @buttonAction="applyFilter"/>
      </div>  
    </div>
    <div class="layout">
      <div>
        <div class="rounded p-2 h-full bg-white">
          <div class="flex items-center flex-wrap mb-4">
             <div class="" v-for="(status, index) in filtersList" :key="index" >
            <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.selected" :btnText="status.displayText" :lastInlist="filtersList.length === index + 1"/>     
          </div>
          </div>
          <div class="mb-4">
            <p class=" text-center text-gray3 mb-4">Or Select Custom Dates</p>
            <div class=" grid grid-cols-2 gap-4">
              <!-- <StatusBtn class=" whitespace-nowrap" @buttonAction="customeStartEnd" :selected="isCustom" :btnText="'Custom'" :lastInlist="true"/>  -->

              <div class="mb-4 sm:mb-0">
                <TextField
                :placholderText="`From`"
                :setReadOnly="true"
                :autoFocus="false"
                :inputext="startDate"
                :showDate="true"
                :lableText="'From'"
                :inputId="'startkid'"
                @onClickInputAction="startDatePickShow = true" />

                <div v-if="startDatePickShow" class="popup_overlay">
                    <div style="height: 405px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                        <DateTimePickerCustom
                            v-model="startDate"
                            v-if="startDatePickShow"
                            :showDate="false"
                            :title="'From'"
                            :showOnlyDate="true"
                            :hideGoToday="true"
                            :showStartDate="true"
                            :projectSection="true"
                            @input="updateDate('start')"
                            @closeDateTimepickerDialog="startDatePickShow = false">
                        </DateTimePickerCustom>
                    </div>
                </div>
                
              </div>
              <div class="mb-4 sm:mb-0">
                <TextField
                :placholderText="`To`"
                :setReadOnly="true"
                :autoFocus="false"
                :inputext="endDate"
                :showDate="true"
                :lableText="'To'"
                :inputId="'endatekid'"
                @onClickInputAction="endDatePickShow = true" />

                <div v-if="endDatePickShow" class="popup_overlay">
                    <div style="height: 405px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                        <DateTimePickerCustom
                            v-model="endDate"
                            v-if="endDatePickShow"
                            :showDate="false"
                            :title="'To'"
                            :showOnlyDate="true"
                            :hideGoToday="true"
                            :showStartDate="true"
                            :projectSection="true"
                            @input="updateDate('end')"
                            @closeDateTimepickerDialog="endDatePickShow = false">
                        </DateTimePickerCustom>
                    </div>
                </div>
            </div>
            </div>
            <p v-if="dateError !== ''" class="text-error heading-6 mt-4">{{dateError}}</p>
          </div>
          <div>
            <p class="text-right text-primary">
              <span @click="resetFilters" class="cursor-pointer">Reset filter</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Button from '@/View/components/globalButton.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import TextField from '@/View/components/textfield.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import moment from 'moment'
export default {
  props: ['navigationDialog'],
  components: {
    Button,
    StatusBtn,
    DateTimePickerCustom,
    TextField,
  },
  data () {
    return {
      filtersList: [
        {
          selected: true,
          displayText: 'All',
          value: 0,
          id: 1,
        },
        {
          selected: false,
          displayText: 'Today',
          value: 1,
          id: 2,
        },
        {
          selected: false,
          displayText: 'Yesterday',
          value: -1,
          id: 3,
        },
        {
          selected: false,
          displayText: 'Prev 4 days',
          value: -4,
          id: 4,
        },
        {
          selected: false,
          displayText: 'Next 4 days',
          value: 4,
          id: 5,
        },
        {
          selected: false,
          displayText: 'Prev 7 days',
          value: -7,
          id: 6,
        },
        {
          selected: false,
          displayText: 'Next 7 days',
          value: 7,
          id: 7,
        },
        {
          selected: false,
          displayText: 'Prev 15 days',
          value: -15,
          id: 8,
        },
        {
          selected: false,
          displayText: 'Next 15 days',
          value: 15,
          id: 9,
        },
        {
          selected: false,
          displayText: 'Prev 30 days',
          value: -30,
          id: 10,
        },
        {
          selected: false,
          displayText: 'Next 30 days',
          value: 30,
          id: 11,
        },
      ],
      isCustom: false,
      startDatePickShow: false,
      endDatePickShow: false,
      startDate: '',
      endDate: '',
      dateError: '',
    }
  },
  beforeMount () {},
  watch : {
    navigationDialog: {
      handler () {
        if (this.navigationDialog) {
          document.body.style = 'overflow: hidden;'
        }
      }
    }
  },
  mounted () {
    
  },
  computed: {},
  methods: {
    resetFilters () {
      this.filtersList.map((data, i) => {
        this.filtersList[i].selected = false
      })
      this.endDate = ''
      this.startDate = ''
      this.isCustom = false
      this.filtersList[0].selected = true
    },
    updateDate (from) {
      if (!this.isCustom) {
        if (from ==='start') {
          this.endDate = ''
        } else {
          this.startDate = ''
        }
      }
      this.customeStartEnd()
      console.log('startDate', this.startDate);
      console.log('endDate', this.endDate);
      if (new Date(this.endDate) < new Date(this.startDate)) {
        this.dateError = 'End date should be greater than start date'
      } else {
        this.dateError = ''
      }
    },
    customeStartEnd () {
      this.isCustom = true
      this.filtersList.map((data, i) => {
        this.filtersList[i].selected = false
      })
    },
    toggleActiveAndInactiveA (id, dataVal) {
      this.isCustom = false
      let current = new Date()
      console.log('current', current);
        this.filtersList.map((data, i) => {
            if (dataVal.id === data.id) {
              if (data.value === 0) {
                this.startDate = ''
                this.endDate = ''
              } else if (data.value < 0) {
                this.startDate =  moment(current.setDate(current.getDate() - Math.abs(data.value))).format('YYYY-MM-DD')
                this.endDate = moment(new Date).format('YYYY-MM-DD')
              } else if (data.value === 1) {
                this.endDate = moment(new Date).format('YYYY-MM-DD')
                this.startDate = moment(new Date).format('YYYY-MM-DD')
              } else {
                this.endDate = moment(current.setDate(current.getDate() + Math.abs(data.value))).format('YYYY-MM-DD')
                this.startDate = moment(new Date).format('YYYY-MM-DD')
              }
              this.filtersList[i].selected = true
            } else {
              this.filtersList[i].selected = false
            }
        })
    },
    applyFilter () {
      let data = null
      if (this.startDate !== '' && this.endDate !== '') {
        data = {
          apply: true,
          startDate:this.startDate,
          endDate:this.endDate,
        }
        document.body.style = 'overflow: visible;'
        this.$emit('applyFilters', data)
      } else if (this.isCustom && (this.startDate !== '' && this.endDate === '' || this.startDate === '' && this.endDate !== '') ) {
          this.dateError = 'To date should be greater than from date'
      } else {
        document.body.style = 'overflow: visible;'
        this.$emit('applyFilters', null)
      }
    },
    cancelFilterOptions () {
      document.body.style = 'overflow: visible;'
      this.$emit('applyFilters', false)
    },
  }
}
</script>

<style scoped>
</style>

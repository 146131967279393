<template>
  <div class="">
    <div class="mt-8">
        <div class="flex flex-wrap mb-4">
          <div class="" v-for="(status, index) in empStatusList" :key="index" >
            <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Task...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewQuote(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add New'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="quote_name_col w-20 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-72 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
               <div v-if="titleObj[4].icon !== ''">
                  <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
          </div>
          <div v-if="taskList.length > 0">
            <div v-for="(data, index) in taskList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="goToDetail(data)">
              <div class="quote_name_col w-20">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6"># {{data.taskNumber}}</span>
                </div>
              </div>
              <div class="quote_name_col w-72">
                <div class="">
                  <!-- <p class="  text-gray4 heading-6">{{}}</p> -->
                  <p class="text-gray4 heading-6">{{data.taskTitle}}</p>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6">{{data.taskDate | formatDataeTime}}</span>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6">{{data.startTime | formatDataeTime}}</span>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6">{{data.endTime | formatDataeTime}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
          <Pagination
            :totalDataCount="totalCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getQuoteDataFromPagination"/>
        </div>
        </div>
      </div>
     </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
export default {
  components: {
    StatusBtn,
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Task #', icon: '', sortName: 'taskNumber'},
        {title: 'Title', icon: '', sortName: 'taskTitle'},
        {title: 'Task Date', icon: 'DESC', sortName: 'taskDate'},
        {title: 'Task Start', icon: '', sortName: 'startTime'},
        {title: 'Task End', icon: '', sortName: 'endTime'}
      ],
      empStatusList: [],
      taskList: [],
      searchValue: '',
      getTaskDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalCount: 0,
      customerRedirectId: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'taskDate',
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
        selectedEmpId: '',
        isRepeat: '',
      },
    };
  },
  props: ["customerId"],
  created() {},
  mounted() {
    this.filterObject.customerId = parseInt(this.customerId)
    this.customerRedirectId = this.filterObject.customerId
    this.getEmpList()
    this.getTaskList()
     this.getTaskDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getTaskList()
      }
      this.addLead = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    addNewQuote () {
      this.$router.push({name: 'AddNewTask', params: {taskId: 0}, query: {customerId: parseInt(this.customerId)}})
    },
    goToDetail (data) {
      this.$router.push({name: 'TaskDetail', params: {taskId: data.taskId}, query: {date: data.taskDate}})
    },
    getQuoteDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getTaskList(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getTaskList(true)
    },
    searchCall (data) {
      this.searchValue = data
      this.getTaskDebounce()
    },
    toggleActiveAndInactiveB (index) {
      let filter = []
      if (this.empStatusList[index].fullName.toLowerCase() === 'all') {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      } else {
        this.empStatusList[index].selected = !this.empStatusList[index].selected
        this.filterObject.selectedEmpId = []
        this.empStatusList.forEach((a, id) => {
          if (this.empStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.selectedEmpId.push(a.userDetailId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.selectedEmpId.push(a.userDetailId)
            }
          }
        })
        this.empStatusList[0].selected = false
      }
      if (filter.length === this.empStatusList.length - 1) {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      let arry = this.empStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.empStatusList.length) {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
      this.getTaskList(false)
    },
    getEmpList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : [] 
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
            temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
          }
          const newFirstElement = {
            userDetailId: 0,
            fullName: "All",
            selected: true
          }
          this.empStatusList = [newFirstElement].concat(temp)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getTaskList (showlistLoader) {
      if (showlistLoader && this.searchValue !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchValue === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      MyJobApp.GetTaskListingForList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchValue,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        parseInt(this.customerId),
        '',
        '',
        this.filterObject.isRepeat,
        this.filterObject.selectedEmpId,
        '',
        response => {
          this.totalCount = response.Data.count
          this.taskList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
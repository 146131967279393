<template>
  <div class="" :class="$route.name !== 'LeadDetail' ? 'mt-12' : 'mt-8'">
     <div class=" bg-white rounded-xl" :class="$route.name !== 'LeadDetail' ? 'card p-4 ' : ''">
      <div class="relative" v-if="$route.name !== 'LeadDetail'">
      <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
        Quotation list ({{totalQuoteCount}})
      </div>
      </div>
      <div class="" :class="$route.name !== 'LeadDetail' ? 'mt-8' : 'mt-2'">
        <div class="flex justify-between flex-wrap">
          <div class="flex flex-wrap mb-4">
            <div class="" v-for="(status, index) in statusList" :key="index" >
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactive(index)" :selected="status.selected" :btnText="status.statusName" :lastInlist="statusList.length === index + 1"/>     
            </div>
          </div>
          <div>
            <span @click="filterShow = true" :class="` text-3xl ${filterApply ? 'text-success' : 'text-primary'}`"><i class="fa-solid fa-filter"></i></span>
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Quotation...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewQuote(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add New'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="quote_name_col w-32 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-56 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-56 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-32 uppercase  flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
               <div v-if="titleObj[4].icon !== ''">
                  <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-56 uppercase  flex items-center" :class="titleObj[5].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(5)">{{titleObj[5].title}}</span>
               <div v-if="titleObj[5].icon !== ''">
                  <descIconSvg v-if="titleObj[5].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
          </div>
          <div v-if="quotationList.length > 0">
            <div v-for="(data, index) in quotationList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="goToDetail(data.quotationMasterId)">
              <div class="quote_name_col w-32">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6"># {{data.quotationNumber}}</span>
                </div>
              </div>
              <div class="quote_name_col w-56">
                <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <p class="text-text1 font-bold">{{data.companyName}}</p>
                <p class="text-gray4 text">{{data.customerFirstName}} {{data.customerLastName}}
                  <span v-if="!data.isLead" class="text-primary ">(Customer)</span>
                  <span v-if="data.isLead" class="text-success">(Lead)</span>
                </p>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                <span class="text-gray4 heading-6">{{data.grandTotal | amountOnlyNumber}}</span>
                </div>
              </div>
              <div class="quote_name_col w-56">
                <div class="flex items-center">
                <span class="text-gray4 heading-6">{{data.expiryDate | formatDataeTime}}</span>
                </div>
              </div>
              <div class="quote_name_col w-32">
                <div class="flex items-center">
                <span :style="`color: ${data.statusColor};`" class=" text-gray4 heading-6">{{data.statusName}}</span>
                </div>
              </div>
              <div class="quote_name_col w-56">
                <div class="flex items-center">
                <span class="text-gray4 heading-6">{{data.quoteDate | formatDataeTime}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
          <Pagination
            :totalDataCount="totalQuoteCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getQuoteDataFromPagination"/>
        </div>
        </div>
      </div>
     </div>
     <invoice-filter-drawer @applyFilters="applyFilters" :navigationDialog="filterShow"/>
   </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import InvoiceFilterDrawer from '../invoice/components/InvoiceFilterDrawer.vue'
export default {
  name: "quotation",
  components: {
    StatusBtn,
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    Button,
    InvoiceFilterDrawer,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      filterShow: false,
      filterApply: false,
      titleObj: [
        {title: 'Quotation #', icon: '', sortName: 'quotationNumber'},
        {title: 'Company Name', icon: '', sortName: 'companyName'},
        {title: 'Grand Total', icon: '', sortName: 'grandTotal'},
        {title: 'Expiry Date', icon: 'DESC', sortName: 'expiryDate'},
        {title: 'Status', icon: '', sortName: 'statusName'},
        {title: 'Quotation Date', icon: '', sortName: 'quoteDate'}
      ],
      statusList: [
        {
          statusName: 'All',
          statusId: 0,
          selected: true,
        },
        {
          statusName: 'Open',
          statusId: 1,
          selected: true,
        },
        {
          statusName: 'Approved',
          statusId: 2,
          selected: true,
        },
        {
          statusName: 'Rejected',
          statusId: 3,
          selected: true,
        },
        {
          statusName: 'Cancelled',
          statusId: 4,
          selected: true,
        },
        {
          statusName: 'Draft',
          statusId: 5,
          selected: true,
        },
        {
          statusName: 'Expired',
          statusId: 6,
          selected: true,
        }
      ],
      quotationList: [],
      searchForQuotation: '',
      getQuotationDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalQuoteCount: 0,
      customerRedirectId: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'quoteDate',
        statusList: [],
        customerId: '',
        from: '',
        to: '',
      },
    };
  },
  props: ["customerId"],
  created() {},
  mounted() {
    if (this.customerId !== undefined) {
      this.filterObject.customerId = this.customerId
      this.customerRedirectId = this.customerId
    } else {
      this.filterObject.customerId = 0
    }
    // this.getQuoteStatus()
    this.getQuoteList()
     this.getQuotationDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getQuoteList()
      }
      this.addLead = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    applyFilters (data) {
    console.log(data);
    if (data === null) {
      this.filterObject.from = ''
      this.filterObject.to = ''
      this.filterApply = false
      this.getQuoteList()
    } else if (!data) {
      this.filterShow = false
      return
    } else {
      this.filterApply = true
      this.filterObject.from = data.startDate
      this.filterObject.to = data.endDate
      this.getQuoteList()
    }
    this.filterShow = false
  },
    addNewQuote () {
      this.$router.push({name: 'CreateQuote', params: {quoteId: 0}, query: {customerId: this.customerRedirectId}})
    },
    goToDetail (Id) {
      this.$router.push({name: 'quoteDetail', params: {quoteId: Id}})
    },
    getQuoteDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getQuoteList(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getQuoteList(true)
    },
    openLeadDetails (id) {
      this.$router.push({name: 'customerDetails', params: {customerId: id}})
    },
    searchCall (data) {
      this.searchForQuotation = data
      this.getQuotationDebounce()
    },
    toggleActiveAndInactive (index) {
      let filter = []
      if (this.statusList[index].statusName.toLowerCase() === 'all') {
        this.statusList[0].selected = true
        this.filterObject.statusList = []
        for (let index = 0; index < this.statusList.length; index++) {
          this.statusList[index].selected = true
        }
      } else {
        this.statusList[index].selected = !this.statusList[index].selected
        this.filterObject.statusList = []
        this.statusList.forEach((a, id) => {
          if (this.statusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.statusList.push(a.statusId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.statusList.push(a.statusId)
            }
          }
        })
        this.statusList[0].selected = false
      }
      if (filter.length === this.statusList.length - 1) {
        this.statusList[0].selected = true
        this.filterObject.statusList = []
        for (let index = 0; index < this.statusList.length; index++) {
          this.statusList[index].selected = true
        }
      }
      let arry = this.statusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.statusList.length) {
        this.statusList[0].selected = true
        this.filterObject.statusList = []
        for (let index = 0; index < this.statusList.length; index++) {
          this.statusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
      // this.getQuoteList()
    },
    // getQuoteStatus () {
    //   MyJobApp.getQuotationStatusList(
    //     response => {
    //       let temp = response.Data
    //       for (let index = 0; index < temp.length; index++) {
    //         temp[index].selected = true
    //       }
    //       const newFirstElement = {
    //         statusId: 0,
    //         statusName: "All",
    //         selected: true
    //       }
    //       this.statusList = [newFirstElement].concat(temp)
    //       this.$store.dispatch('SetLoader', {status: false, message: ''})
    //     },
    //     () => {
    //       this.resetPaginationValues = false
    //       this.$store.dispatch('SetLoader', {status: false, message: ''})
    //     }
    //   )
    // },
    getQuoteList (showlistLoader) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForQuotation === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      // this.filterObject.statusList = []
      // this.statusList.map((data) => {
      //   if (data.selected) {
      //     this.filterObject.statusList.push(data.statusId)
      //   }
      // })
      MyJobApp.getQuotationList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForQuotation,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.statusList,
        this.filterObject.customerId,
        this.filterObject.from,
        this.filterObject.to,
        response => {
          this.totalQuoteCount = response.Data.count
          this.quotationList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
<template>
  <div id="custActivityList">
    <div class=" flex justify-between mb-2">
        <p class="text-text1 font-bold heading-4 ">Activities</p>
    </div>
    <div class="flex flex-wrap items-center py-2">
          <div class="w-72 ">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Activity...`"/>
          </div>
        </div>
    <div v-if="activitieList.length > 0">
        <div v-for="(data, index) in activitieList" :key="index"  class=" m-2 mx-1  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 " :class="(data.entitySlug === 'payment' || data.entitySlug === 'quotation' || data.entitySlug === 'invoice' || data.entitySlug === 'job' || data.entitySlug === 'visit'  || data.entitySlug === 'lead' || data.entitySlug === 'shift' || data.entitySlug === 'customer' || data.entitySlug === 'employee' || data.entitySlug === 'tax' || data.entitySlug === 'item' || data.entitySlug === 'payment_method' || data.entitySlug === 'expense' || data.entitySlug === 'request' || data.entitySlug === 'task') && ((data.entityDetailId > 0 || data.entitySubDetailId > 0) || (data.entityDetailId === 0 && data.entitySubDetailId === 0)) ? '' : ''">
              <div class="">
                <div class="flex justify-between">
                  <div class="flex">
                    <div>
                      <span v-html="getIcons(data.entitySlug)"></span>
                    </div>
                    <div class="pl-3">
                      <div class="text-gray4 heading-5">{{data.firstName}} {{data.lastName}}</div>
                      <div class="text-gray3 heading-5">{{data.activityTitle}}</div>
                      <!-- <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.activityDesc !== '' && data.entitySlug !== 'visit' && !data.isExpand">
                        <span v-if="data.activityDesc.length > 100">{{ data.activityDesc.substr(0, 100) }}...</span>
                        <span v-else>{{data.activityDesc}}</span>
                      </div>
                      <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.activityDesc !== '' && data.entitySlug !== 'visit' && data.isExpand">
                        <span>{{data.activityDesc}}</span>
                      </div>
                      <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.activityDesc !== '' && data.entitySlug === 'visit'">
                        <span>{{data.activityDesc}}</span>
                      </div> -->
                      <div class="text-gray3 heading-5 whitespace-pre-line" v-if="data.activityDesc !== ''">
                        <span class="mb-0 whitespace-pre-line">
                            {{data.activityDesc === '' ? '-' : (data.activityDesc.length > 125)? data.readMoreNote ?  data.activityDesc : data.activityDesc.substr(0, 125) : data.activityDesc}}
                            <span v-if="data.activityDesc.length > 125" class="text-primary cursor-pointer heading-7" @click="data.readMoreNote = !data.readMoreNote">
                             {{data.readMoreNote === false ? 'Read More...' : 'Read Less...'}}</span>
                             </span>
                      </div>
                      <div>
                        <div v-if="data.attachmentList.length > 0" class="">
                            <p class="text-primary heading-6 mb-2">Attachments:</p>
                            <div class="flex gap-4" style="flex-wrap: wrap;">
                                <div class=" card rounded-xl border-t border-gray-100 bg-white attachment-card cursor-pointer group" style="width: 120px; height: 80px"  v-for="(attach, a) in data.attachmentList " :key="a" @click="showFullImage(attach)">
                                    
                                    <div v-if="attach.docType === 'image'" class="text-center">
                                        <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                                        <div class="invisible group-hover:visible absolute bg-gray4 w-full min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex ">{{ attach.displayFileName }}</div>
                                    </div>
                                    <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                                        <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                                        <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                                        <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                                    </div>
                                    <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                                        <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                                    </div>
                                    <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                                        <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                                    </div>
                                </div>
                            </div>
                            </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-gray4 heading-5" :class="(data.entitySlug === 'payment' || data.entitySlug === 'invoice' || data.entitySlug === 'job' || data.entitySlug === 'quotation' || data.entitySlug === 'shift' || data.entitySlug === 'customer' || data.entitySlug === 'lead' || data.entitySlug === 'employee'  || data.entitySlug === 'visit' || data.entitySlug === 'tax' || data.entitySlug === 'item' || data.entitySlug === 'payment_method' || data.entitySlug === 'expense' || data.entitySlug === 'request') && ((data.entityDetailId > 0 || data.entitySubDetailId > 0) || (data.entityDetailId === 0 && data.entitySubDetailId === 0)) ? 'hover:text-primary' : ''">{{data.addedDate | dateManyDayAgo}}
                
                  </div>
                </div>
              </div>
            </div>
         
    </div> 
    <div v-else>
      <NoRecords :alertMessage="` No records found`" />
    </div> 
    <div ><Pagination
            :defaultPerPage="10"
            :totalDataCount="totalCount"
            :resetPagination="resetPagination"
            @callApiAsPerPagination="getDataFromPagination"/>
        </div>
    <FullImage v-if="shoFullImage" :fileVaultId="fileVaultId" :fileType="fileType" :fileName="fileName" />  
    
  </div>
</template>
<script>
// import Button from '@/View/components/globalButton.vue'
import MyJobApp from '@/api/MyJobApp.js'
// import FullImage from './components/FullImage.vue'
import SearchTextInput from '@/View/components/searchTextInput.vue'
// import AddUpdateNote from './components/AddUpdateNote.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import NoRecords from '@/View/components/noRecords.vue'
import FullImage from '../../endUserNewFlow/components/FullImage.vue'
export default {
  name: "lead",
  components: {
    // Button,
    // AddUpdateNote,
    SearchTextInput,
    Pagination,
    NoRecords,
    FullImage,
  },
  mixins: [deboucneMixin],
  data() {
    return {
        getDebounceData: null,
        totalCount: 0,
        resetPagination: false,
        mobileView: false,
        activitieList: [],
        searchVal: '',
        pathPrefix: '',
        fileVaultId: 0,
        fileType: '',
        fileName: '',
        shoFullImage: false,
        filterObject: {
        start: 0,
        length: 10,
        isActive: true,
        sortBy: 'addedDate',
        entStatusList: [],
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
    };
  },
  props: ['DetailObj'],
  created() {},
  mounted() {
    this.getDebounceData = this.debounce(function () {
      this.resetPagination = true
    }, 500)
    this.getAcitivityList()
    this.$root.$on('fullImageHandlerEnd', (response) => {
        this.shoFullImage = response
    })
  },
  methods: {
     getIcons (data) {
    if (data === 'payment') {
        return `<span class="text-success heading-4"><i class="fa-solid fa-dollar"></i></span>`
      } else if (data === 'invoice') {
        return `<span class="text-error heading-4"><i class="fas fa-file-invoice"></i></span>`
      } else if (data === 'shift') {
        return `<span class="text-success heading-4"><i class="fas fa-clock"></i></span>`
      } else if (data === 'quotation') {
        return `<span class="text-primary heading-4"> <i class="fab fa-quora"></i></span>`
      } else if (data === 'visit') {
        return `<span class="text-primary heading-4"><i class="fas fa-calendar-alt"></i></span>`
      } else if (data === 'job') {
        return `<span class="text-primary heading-4"><i class="fas fa-briefcase"></i></span>`
      } else if (data === 'customer') {
        return `<span class="text-indigo-500 heading-4"><i class="fas fa-users"></i></span>`
      } else if (data === 'lead') {
        return `<span class="text-secondary heading-4"><i class="fas fa-comment-dollar"></i></span>`
      } else if (data === 'employee') {
        return `<span class="text-primary heading-4"><i class="fas fa-user-tie"></i></span>`
      } else if (data === 'payment_method') {
        return `<span class="text-primary heading-4"><i class="far fa-money-bill-alt"></i></span>`
      } else if (data === 'tax') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-percent"></i></span>`
      } else if (data === 'item') {
        return `<span class="text-blue1 heading-4"><i class="fa-solid fa-box"></i></span>`
      } else if (data === 'expense') {
        return `<span class="text-blue1 heading-4"><i class="fa-solid fa-money-bill-transfer"></i></span>`
      } else if (data === 'request') {
        return `<span class="text-success heading-4"><i class="fa-solid fa-code-pull-request"></i></span>`
      } else if (data === 'task') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-clipboard-list"></i></span>`
      } else if (data === 'check_list_item') {
        return `<span class="text-gray-500 heading-4"><i class="fa-solid fa-list-check"></i></span>`
      } else if (data === '') {
        return `<span class="text-gray4 heading-4"><i class="fa-regular fa-circle-dot"></i></span>`
      }
    },
    getDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
       window.scrollTo({
          top: document.getElementById('custActivityList').offsetTop - 100,
          left: 0,
          behavior: 'smooth'
        })
      console.log('check call 11');
      this.getAcitivityList(true)
    },
    showFullImage (data) {
        this.fileVaultId = data.fileVaultId
        this.fileType = data.docType
        this.fileName = data.displayFileName
        this.shoFullImage = true
    },
    searchCall (data) {
      console.log('check 11');
      this.searchVal = data
      this.filterObject.start = 0
      this.getDebounceData()
    },
    getAcitivityList () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getCustomerActivity(
        this.filterObject.start,
        this.filterObject.length,
        this.searchVal,
        "",
        "",
        this.DetailObj.detailId,
          response => {
            this.resetPagination = false
            this.activitieList = response.Data.tableRow === null ? [] : response.Data.tableRow
            this.pathPrefix = response.Data.pathPrefix
            this.totalCount = response.Data.count
            console.log('responser', response);

            let templist = []
            if (response.Data.tableRow !== null) {
                response.Data.tableRow.forEach(data=> {
                   let attachmentList = JSON.parse(data.noteAttachments).attachmentList

                   let attach = []

                    if (attachmentList !== null) {
                        attachmentList.forEach(a => {
                            let temp = a.fileType.split('/')
                            console.log('temp', temp)
                            let type = ''
                            if (temp[0] === 'image' ) {
                                type = 'image'
                            } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                              type = 'pdf'
                            } else if (temp[0] === 'video' ) {
                              type = 'video'
                            }
                            attach.push({...a, docType: type})
                        })
                    }

                    templist.push({...data, charLimit: 120, attachmentList: attach, readMoreNote: false})
                })      
            }

            this.activitieList = templist
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPagination = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
  },
  beforeDestroy() {
    this.$root.$off("fullImageHandler");
  },
};
</script>
<style scoped>
.attachment-card {
  display: flex;
  align-items: center;
  position: relative;
}
.scroll-img-view{
  height: 104px;
}
/* .note_icn {
  visibility:hidden;
}
.note_icn_box:hover .note_icn {
  visibility: visible !important;
} */
.zIndex {
  z-index: 100 !important;
}
</style>
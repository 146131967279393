<template>
      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative">
        <div>
          <div class="bg-success flex justify-between p-2">
            <div class=" font-medium text-white heading-3">
              {{contDetail.contactId > 0 ? 'Update Contact' : 'Add Contact'}}
            </div>
            <div class=" text-error heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="removeConfirm = true" v-if="contDetail.contactId > 0 && !disableBtn">
              <i class="fa-solid fa-trash"></i>
            </div>
          </div>
          <div class="p-4">
            <div class="sm:grid grid-cols-12 gap-3">
              <div class="col-span-6 mb-4 sm:mb-0">
                <TextField
                  :inputId="'cusfirstnameid'"
                  :textMaxlength="50"
                  :showcharLimit="true"
                  :inputext="contDetail.firstName"
                  :fieldError="errObj.firstNameErr !== ''"
                  :placholderText="`First Name`"
                  :lableText="'First Name'"
                  :autoFocus="true"
                  @keyPressAction="errObj.firstNameErr = ''"
                  @inputChangeAction="(data) => contDetail.firstName = data"  />

                  <p class="text-error heading-7">{{errObj.firstNameErr}}</p>
               </div>
              <div class="col-span-6 mb-4 sm:mb-0">
                <TextField
                  :inputId="'cuslastnameid'"
                  :textMaxlength="50"
                  :showcharLimit="true"
                  :inputext="contDetail.lastName"
                  :fieldError="errObj.lastNameErr !== ''"
                  :placholderText="`Last Name`"
                  :lableText="'Last Name'"
                  :autoFocus="false"
                  @keyPressAction="errObj.lastNameErr = ''"
                  @inputChangeAction="(data) => contDetail.lastName = data"  />

                  <p class="text-error heading-7">{{errObj.lastNameErr}}</p>
               </div>
              <div class="col-span-6 mb-4 sm:mb-0">
                <NumberInput
                :showcharLimit="true"
                :inputId="'custprimnumber'"
                :inputext="contDetail.phoneNumber"
                :textMaxlength="12"
                :placholderText="`Primary Number`"
                :lableText="'Primary Number'"
                :inputType="'text'"
                :autoFocus="false"
                :fieldError="errObj.primNumErr !== '' || numberExist1"
                :setReadOnly="false"
                @keyPressAction="errObj.primNumErr = ''; numberExist1 = false"
                @blurAction="checkContactNumber('prime')"
                @inputChangeAction="(data) => contDetail.phoneNumber = data"  />

                  <p class="text-error heading-7">{{errObj.primNumErr}}</p>
                  <div v-if="numberExist1">
                    <p v-if="numberExist1" class="text-error  heading-7">Number already in use, please use another</p>
                    <span v-for="(data, index) in existArr1" :key="index">
                      <li class="heading-6 text-primary cursor-pointer" @click="routingTodata(data)">{{ data.firstName }} {{ data.firstName }}({{ data.isLead ? 'Lead' : 'Customer'}})</li>
                    </span>
                  </div>
               </div>
               <div class=" col-span-6 mb-4 sm:mb-0">
                    <NumberInput
                    :inputId="'custothernumber'"
                    :inputext="contDetail.otherNumber"
                    :textMaxlength="12"
                    :placholderText="`Other Number`"
                    :lableText="'Other Number'"
                    :showcharLimit="true"
                    :inputType="'text'"
                    :autoFocus="false"
                    :fieldError="errObj.otherNumberErr !== '' || numberExist2"
                    :setReadOnly="false"
                    @keyPressAction="errObj.otherNumberErr = ''; numberExist2 = false"
                    @blurAction="checkContactNumber('other')"
                    @inputChangeAction="(data) => contDetail.otherNumber = data"  />

                  <p class="text-error heading-7">{{errObj.otherNumberErr}}</p>
                  <div v-if="numberExist2">
                    <p v-if="numberExist2" class="text-error  heading-7">Number already in use, please use another</p>
                      <span v-for="(data, index) in existArr2" :key="index">
                        <li class="heading-6 text-primary cursor-pointer" @click="routingTodata(data)">{{ data.firstName }} {{ data.firstName }}({{ data.isLead ? 'Lead' : 'Customer'}})</li>
                      </span>
                    </div>
                  </div>
              <div class="col-span-6 mb-4 sm:mb-0">
                <TextField
                    :inputId="'customeremail'"
                    :inputext="contDetail.emailAddress"
                    :placholderText="`Email Address`"
                    :showcharLimit="true"
                    :textMaxlength="250"
                    :lableText="'Email Address'"
                    :inputType="'text'"
                    @blurAction="checkEmail"
                    :autoFocus="false"
                    :setReadOnly="false"
                    @keyPressAction="errObj.emailErr = '';emailExist = false"
                    :fieldError="errObj.emailErr !== '' || emailExist"
                    @inputChangeAction="(data) => contDetail.emailAddress = data"  />

                  <p class="text-error heading-7">{{errObj.emailErr}}</p>
                  <div v-if="emailExist">
                    <p v-if="emailExist" class="text-error  heading-7">Email already in use, please use another</p>
                    <span v-for="(data, index) in existArr3" :key="index">
                      <li class="heading-6 text-primary cursor-pointer" @click="routingTodata(data)">{{ data.firstName }} {{ data.firstName }}({{ data.isLead ? 'Lead' : 'Customer'}})</li>
                    </span>
                  </div>
               </div>
            </div>
            <div class="py-4">
            <CheckBoxInput
            :checkValue="contDetail.isPrimary"
            :label="`Primary Contact`"
            :disabled="disableBtn"
            @setIsActive="contDetail.isPrimary = !contDetail.isPrimary"  />
        </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="`${cusrDetailId > 0 ? 'Update' : 'Save'}`" @buttonAction="saveContactDetail()"/>
            </div>
            </div>
        </div>
    </div>
    <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" />
  </div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import TextField from '@/View/components/textfield.vue'
import NumberInput from '@/View/components/number-text-field.vue'
import { validateEmail,checkSpeialChar } from "@/utils/validation.js";
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    CheckBoxInput,
    ConfirmBox,
    Button,
    NumberInput,
    TextField
  },
  data () {
    return {
      emailExist: false,
      numberExist2: false,
      numberExist1: false,
      existArr1: [],
      existArr2: [],
      existArr3: [],
      disableBtn: false,
      isConPrimary: false,
      removeConfirm: false,
      cusrDetailId : 0,
        contDetail: {
        contactId: 0,
        customerId: 0,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        otherNumber: '',
        emailAddress: '',
        isPrimary: false,
        },
        errObj: {
          firstNameErr: '',
          lastNameErr: '',
          primNumErr: '',
          otherNumberErr: '',
          emailErr: ''
        }
    }
  },
  props: ["updatedLeadetail", "detailObj"],
   created() {
  },
  mounted () {
    this.$root.$on('confirmBoxHandler', (response) => {
      if (response) {
        this.deleteApi()
      }
      document.body.style = 'overflow: visible;'
      this.removeConfirm = false
    })
    if (this.updatedLeadetail !== null && this.updatedLeadetail !== undefined ) {
        this.contDetail.contactId = this.updatedLeadetail.contactId
        this.contDetail.firstName = this.updatedLeadetail.firstName
        this.contDetail.lastName = this.updatedLeadetail.lastName
        this.contDetail.phoneNumber = this.updatedLeadetail.phoneNumber
        this.contDetail.otherNumber = this.updatedLeadetail.otherNumber
        this.contDetail.emailAddress = this.updatedLeadetail.emailAddress
        this.contDetail.isPrimary = this.updatedLeadetail.isPrimary
    }
    if (this.detailObj.contacts.length <= 1 && this.contDetail.contactId > 0) {
      this.disableBtn = true
    } else {
      this.disableBtn = false
    }
    document.body.style = 'overflow: hidden;'
    this.contDetail.customerId = parseInt(this.$route.params.leadId)
  },
  watch: {},
  methods: {
    saveContactDetail () {
      let isValid = true
      if (this.contDetail.firstName.trim() === '') {
        isValid = false
        this.errObj.firstNameErr = 'First name is required'
      }
      if (this.errObj.primNumErr !== '' || this.errObj.otherNumberErr !== "" || this.errObj.emailErr !== '' || this.numberExist1 || this.numberExist2 || this.emailExist) {
        isValid = false
      }
      if (isValid) {
         MyJobApp.UpdateLeadsContact(
         this.contDetail,
          response => {
            this.$root.$emit('addContactHandler', true)
            document.body.style = 'overflow: visible;'
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
    deleteApi () {
      MyJobApp.RemoveContacts(
      this.contDetail.contactId,
        response => {
          console.log('response', response);
          this.$root.$emit('addContactHandler', true)
          document.body.style = 'overflow: visible;'
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('addContactHandler')
    },
    checkContactNumber (from) {
        if (from === 'prime') {
          let value = checkSpeialChar(this.contDetail.phoneNumber)
          console.log('value', value)
          if (value) {
            this.errObj.primNumErr = 'Please enter valid contact number'
          } else {
            this.errObj.primNumErr = ''
          }
            if (this.contDetail.phoneNumber !== '' ) {
            this.checkExist('number', this.contDetail.phoneNumber, from)
          }
        }
        if (from === 'other') {
          let value = checkSpeialChar(this.contDetail.otherNumber)
          console.log('value', value)
          if (value) {
            this.errObj.otherNumberErr = 'Please enter valid contact number'
          } else {
            this.errObj.otherNumberErr = ''
          }
          if (this.contDetail.otherNumber !== '' ) {
            this.checkExist('number', this.contDetail.otherNumber, from)
          }
        }
      
    },
    checkExist (from, value, contact) {
      MyJobApp.getLeadCustEmailNumCheck(
        from,
        value,
        this.contDetail.contactId,
        response => {
          if (from === 'number' && response.Data && contact === 'prime') {
            this.numberExist1 = true
            this.existArr1 = response.Data
          } else if (from === 'number' && response.Data && contact === 'other') {
              this.numberExist2 = true
              this.existArr2 = response.Data
          } else if (from === 'email' && response.Data && contact === 'email') {
              this.emailExist = true
              this.existArr3 = response.Data
          }
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      ) 
    },
    routingTodata (value) {
      let url = ''
      if (value.isLead) {
        url = this.$router.resolve({name: 'LeadDetail', params: {leadId: value.customerId}})
      } else {
        url = this.$router.resolve({name: 'customerDetails', params: {customerId: value.customerId}})
      }
      window.open(url.href, '_blank')
    },
    checkEmail () {
      if (this.contDetail.emailAddress !== '' && validateEmail(this.contDetail.emailAddress) === false) {
          this.errObj.emailErr = "Please enter valid email";
          return;
      } else {
        this.errObj.emailErr = "";
        if (this.contDetail.emailAddress !== '') {
          this.checkExist('email', this.contDetail.emailAddress, 'email')
        }
        // this.verifycustomerDataExist('customer_email', this.contDetail.emailAddress)
      }
    },
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
    this.$root.$off('confirmBoxHandler')
  }
}
</script>
<style scoped>

</style>
<template>
  <div class="">
    <div class="mt-8">
      <div class="flex flex-wrap mb-2 items-center">
        <!-- <div class="pr-2  text-gray4 heading-5">Entity:</div> -->
        <div class="" v-for="(status, index) in entStatusList" :key="index" >
          <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.selected" :btnText="status.displayText" :lastInlist="entStatusList.length === index + 1"/>     
        </div>
      </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Request...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewRequest(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add New'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="quote_name_col w-20 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-72 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''" >
              <span class="" >{{titleObj[4].title}}</span>
               <div v-if="titleObj[4].icon !== ''">
                  <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
          </div>
          <div v-if="taskList.length > 0">
            <div v-for="(data, index) in taskList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showRequestDetail(data.requestId)">
              <div class="quote_name_col w-20">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6"># {{data.requestId}}</span>
                </div>
              </div>
              <div class="quote_name_col w-72">
                <div class="">
                  <!-- <p class="  text-gray4 heading-6">{{}}</p> -->
                  <p v-if="data.requestTitle !== ''" class="text-gray4 heading-6">{{data.requestTitle}}</p>
                  <p v-else class="text-gray4 heading-6">Request for {{data.companyName}}</p>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6">{{data.startTime | formatDataeTime}}</span>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6">{{data.endTime | formatDataeTime}}</span>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                  <div class="text-gray4 heading-6">
                    <div v-if="data.isConverted || data.isUnschedule || data.isCompleted || data.isArchived">
                      <span v-if="data.isConverted">Converted<span class="text-success h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.isUnschedule">Unscheduled<span class="text-yellow-300 h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.isCompleted">Assessment Completed<span class="text-warning h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.isArchived">Archived<span class="text-gray3 h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                    </div>
                    <div v-else>
                      <span v-if="data.dateStatus === 'Late'">Late<span class="text-error h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.dateStatus === 'Today'">Today<span class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.dateStatus === 'Upcoming'">Upcoming<span class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                      <span v-if="data.dateStatus === 'New'">New<span class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
          <Pagination
            :totalDataCount="totalCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getQuoteDataFromPagination"/>
        </div>
        </div>
      </div>
     </div>
</template>
<script>
var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
export default {
  components: {
    StatusBtn,
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Request #', icon: '', sortName: 'requestId'},
        {title: 'Title', icon: '', sortName: 'requestTitle'},
        {title: 'Start Time', icon: '', sortName: 'startTime'},
        {title: 'End Time', icon: '', sortName: 'endTime'},
        {title: 'Status', icon: '', sortName: ''},
      ],
      entStatusList: [
        {
          vistStatusId: 1,
          displayText: 'New',
          id: 'new',
          selected: false,
        },
        {
          vistStatusId: 3,
          displayText: 'Today',
          id: 'today',
          selected: false,
        },
        {
          vistStatusId: 2,
          displayText: 'Upcoming',
          id: 'upcoming',
          selected: false,
        },
        {
          vistStatusId: 4,
          displayText: 'Late',
          id: 'late',
          selected: false,
        },
        {
          vistStatusId: 8,
          displayText: 'Unscheduled',
          id: 'unschedule',
          selected: false,
        },
        {
          vistStatusId: 5,
          displayText: 'Assessment Completed',
          id: 'assessmentCompleted',
          selected: false,
        },
        {
          vistStatusId: 7,
          displayText: 'Converted',
          id: 'converted',
          selected: false,
        },
        {
          vistStatusId: 6,
          displayText: 'Archived',
          id: 'archived',
          selected: false,
        },
      ],
      taskList: [],
      searchValue: '',
      getTaskDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalCount: 0,
      customerRedirectId: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'taskDate',
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
        selectedEmpId: '',
        isRepeat: '',
      },
    };
  },
  props: ["customerId"],
  created() {},
  mounted() {
    this.filterObject.customerId = parseInt(this.customerId)
    this.customerRedirectId = this.filterObject.customerId
    this.getTaskList()
     this.getTaskDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getTaskList()
      }
      this.addLead = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    addNewRequest () {
      this.$router.push({name: 'addRequest', params: {requestId: 0}, query: {customerId: parseInt(this.customerId)}})
    },
    showRequestDetail (data) {
      this.$router.push({name: 'requestDetail', params: {requestId: data}})
    },
    getQuoteDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getTaskList(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getTaskList(true)
    },
    searchCall (data) {
      this.searchValue = data
      this.getTaskDebounce()
    },
    toggleActiveAndInactiveA (id, dataVal) {
      this.entStatusList.map((data, i) => {
          if (dataVal.id === data.id) {
            if (this.entStatusList[i].selected) {
               this.filterObject.selectedEmpId = []
               this.entStatusList[i].selected = false
            } else {
                this.filterObject.selectedEmpId = data.id
               this.entStatusList[i].selected = true
            }
          } else {
            this.entStatusList[i].selected = false
          }
      })
      this.resetPaginationValues = true
      // this.getVisitList()
    },
    getTaskList (showlistLoader) {
      if (showlistLoader && this.searchValue !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchValue === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      MyJobApp.requestlist(
        this.filterObject.start,
        this.filterObject.length,
        this.searchValue,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.selectedEmpId,
        parseInt(this.customerId),
        false,
        '',
        '',
        response => {
          this.totalCount = response.Data.count
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < temp.length; index++) {
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            currentDate = new Date(currentDate);
            const cFormated = moment(currentDate).format('YYYY-MM-DD');
            if (temp[index].isUnschedule) {
              temp[index].dateStatus = 'Unschedule';
            } else {
              if ( moment(new Date(temp[index].startTime)).format('YYYY-MM-DD',) === cFormated) {
                temp[index].dateStatus = 'Today';
              } else if ( new Date(temp[index].startTime).getTime() < currentDate.getTime()) {
                temp[index].dateStatus = 'Late';
              } else if (new Date(temp[index].startTime).getTime() > currentDate.getTime()) {
                temp[index].dateStatus = 'Upcoming';
              } else {
                temp[index].dateStatus = 'New';
              }
              console.log('----------------------------------', temp[index]);
            }
          }
          this.taskList = temp
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>